
// PROJECT 1
import featuredImgProject1 from './assets/projectImages/project1/featuredImage.jpeg';
import colorSiteMapProject1 from './assets/projectImages/project1/colorSitePlan.jpg';
import p1i1 from './assets/projectImages/project1/pic01.jpg';
import p1i2 from './assets/projectImages/project1/pic02.jpg';
import p1i3 from './assets/projectImages/project1/pic03.jpg';
import p1i4 from './assets/projectImages/project1/pic04.jpg';

// PROJECT 2
import featuredImgProject2 from './assets/projectImages/project2/featuredImage.jpg';
import colorSiteMapProject2 from './assets/projectImages/project2/colorSitePlan.jpg';
import p2i1 from './assets/projectImages/project2/pic01.jpg';
import p2i2 from './assets/projectImages/project2/pic02.jpg';

// PROJECT 3
import featuredImgProject3 from './assets/projectImages/project3/featuredImage.jpg';
import colorSiteMapProject3 from './assets/projectImages/project3/colorSitePlan.jpg';
import p3i1 from './assets/projectImages/project3/pic01.jpg';
import p3i2 from './assets/projectImages/project3/pic02.jpg';

// PROJECT 4
import featuredImgProject4 from './assets/projectImages/project4/featuredImage.jpg';
import colorSiteMapProject4 from './assets/projectImages/project4/colorSitePlan.jpg';
import p4i1 from './assets/projectImages/project4/pic01.jpg';
import p4i2 from './assets/projectImages/project4/pic02.jpg';

// Project 5
import featuredImgProject5 from './assets/projectImages/project5/featuredImage.jpg';
import colorSiteMapProject5 from './assets/projectImages/project5/colorSitePlan.jpg';
import p5i1 from './assets/projectImages/project5/pic01.jpg';

// Project 6
import featuredImgProject6 from './assets/projectImages/project6/featuredImage.jpeg';
import colorSiteMapProject6 from './assets/projectImages/project6/colorSitePlan.jpg';
import p6i1 from './assets/projectImages/project6/pic01.jpeg';
import p6i2 from './assets/projectImages/project6/pic02.jpeg';


export const galleries = {
  StudioCityHouse: [p1i1, p1i2, p1i3, p1i4],
  EncinoHillsHouse: [p2i1, p2i2],
  MulhollandHouse: [p3i1, p3i2],
  HuttonHouse: [p4i1, p4i2],
  RexfordHouse: [p5i1],
  ChaseHouse: [p6i1, p6i2],
}

export const projectsData = [
  {
    id: 'ChaseHouse',
    featuredImg: featuredImgProject6,
    title: 'CHASE HOUSE',
    stats: {
      location: 'Northridge, California',
      size: '4,340 sf',
      status: 'Construction 2025',
    },
    colorSitePlan: colorSiteMapProject6,
    description: 'A great project that involves adding a second story to a 1960s ranch-style home, significantly increasing its existing square footage. By removing the low-pitched roof forms, we are able to update the look and create the dynamic design our client requested. The forms and exterior materials are intended to evoke the more modernist post-and-beam architecture of the 60s, while the interior spaces have been reconfigured to accommodate an open living concept, favored by a great many of our clients.'
  },
  {
    id: 'StudioCityHouse',
    featuredImg: featuredImgProject1,
    title: 'STUDIO CITY HOUSE',
    stats: {
      location: 'Studio City, California',
      size: '4,147 sf',
      status: 'Under Construction',
    },
    colorSitePlan: colorSiteMapProject1,
    description: 'A modest project consisting of a second story addition to an existing 1960’s ranch style home. Trimming most of the existing overhangs while maintaining the low-slung roof form creates an updated look, while preserving the the existing roof framing. The fully remodeled interior spaces repeat the use of exterior wood siding on select walls and ceilings, while the rooms are arranged to capitalize on the views of the valley beyond.'
  },
  {
    id: 'EncinoHillsHouse',
    featuredImg: featuredImgProject2,
    title: 'ENCINO HILLS HOUSE',
    stats: {
      location: 'Encino, California',
      size: '8,812 sf',
      status: 'Under Construction'
    },
    colorSitePlan: colorSiteMapProject2,
    description: 'Utilizing the existing flat project site, this new construction project answers the client request for an elegant Mediterranean exterior with warm modern interiors. The stone of the tower element is repeated on the interior, as is the limestone paving, creating the look of adaptively reusing an historic building, a practice commonly done in Europe. Extensive site work in the rear yard creates a well appointed space for entertaining and exercise, with mesmerizing views of the canyon.'
  },
  {
    id: 'MulhollandHouse',
    featuredImg: featuredImgProject3,
    title: 'MULHOLLAND HOUSE',
    stats: {
      location: 'Los Angeles, California',
      size: '7,061 sf',
      status: 'Under Construction'
    },
    colorSitePlan: colorSiteMapProject3,
    description: 'The privacy of the site presented an opportunity to create a transparent architectural experience. Arriving in the motor court, you are able to see through ground floor public spaces to the rear yard and panoramic views beyond. A pocketing glass wall connects the sunken living room with the reflecting pool and outdoor entertainment space. Wood screen elements conceal the garage doors and are repeated on the upper level to filter the sunlight and create privacy.'
  },
  {
    id: 'HuttonHouse',
    featuredImg: featuredImgProject4,
    title: 'HUTTON HOUSE',
    stats: {
      location: 'Los Angeles, California',
      size: '5,047 sf',
      status: 'Construction 2025'
    },
    colorSitePlan: colorSiteMapProject4,
    description: 'An extensive remodel and addition to a single story home in the Mediterranean style. The stone tower not only adds a dynamic feature to the exterior, but traditional bottle-glass windows high in the tower introduce natural light to the center of the home. A cozy outdoor living room with fireplace connects the indoor family area with the terraced rear yard, containing a pool and spa, pool house and gardens.'
  },
  {
    id: 'RexfordHouse',
    featuredImg: featuredImgProject5,
    title: 'REXFORD HOUSE',
    stats: {
      location: 'Beverly Hills, California',
      size: '3,637 sf',
      status: 'Under Construction'
    },
    colorSitePlan: colorSiteMapProject5,
    description: 'By reusing the existing exterior stone in a selective way, we created an updated English cottage style for this extensive remodel. New square footage answered the client’s programmatic needs while preserving the rear yard space and allowing for a transformation of the front yard. Adding a circular drive with gates and planters provides a new level of privacy and security for the home, while intimate garden seating around a fountain helps to activate the previously unused front yard.'
  },
]